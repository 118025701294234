import React from 'react';
import BlackHillRecordsLogo from '@globobeet/roundhill-toolkit/svg/BlackHillRecords.svg';

import PageLayout from 'components/page-layout';
import PageContainer from 'components/page-container';

const IndexPage = () => (
  <PageLayout showHeaderLogo={false}>
    <PageContainer as="main">
      <BlackHillRecordsLogo className="block w-full max-w-4xl mx-auto py-16 md:pt-24 md:pb-32" />
    </PageContainer>
  </PageLayout>
);

export default IndexPage;
